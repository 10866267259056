var Navbar = (function () {
    function Navbar() {
        var _this = this;
        this.target = document.getElementsByTagName('nav')[0];
        this.lastPosition = 0;
        this.visible = false;
        this.toggle(this.target);
        window.addEventListener('scroll', function () {
            _this.toggle(_this.target);
        });
    }
    Navbar.prototype.toggle = function (element) {
        if (window.innerWidth < 1024) {
            return;
        }
        if (this.timeout != null) {
            clearTimeout(this.timeout);
        }
        var top = window.pageYOffset || document.documentElement.scrollTop;
        var offsetTop = element.offsetTop;
        var scrollingDown = (top > this.lastPosition);
        var self = this;
        var ms = 500;
        if (!scrollingDown && top >= offsetTop) {
            this.timeout = setTimeout(function () {
                self.showElement();
            }, ms);
        }
        else {
            this.timeout = setTimeout(function () {
                self.hideElement();
            }, ms);
        }
        this.lastPosition = top;
    };
    Navbar.prototype.showElement = function () {
        this.target.classList.remove('small');
    };
    Navbar.prototype.hideElement = function () {
        this.target.classList.add('small');
    };
    return Navbar;
}());
export { Navbar };
